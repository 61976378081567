import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import prototypeGIF from '../images/auto-team-prototype.gif'
import Arrow from 'react-arrow'

const autoteams = ({data}) => (
  <Layout>
    <SEO title="Teams Automation" />
    <div className="Hero">
      <div className="HeroImage">
        <Img fluid={data.autoTeamHero.childImageSharp.fluid} alt="Auto Team Hero Image" />
      </div>
    </div>
    

    <div className="PageContent">
      <h1>Teams Automation</h1>
      <h4>Time: Winter 2019 - Spring 2020</h4>

      <h2>Background</h2>
      <p>Teams are a way of grouping users. They play a key role in <a href="https://www.inkling.com/products/learning-pathways/">Inkling Learning Pathways</a>.</p> <p>Members of teams have a role: manager, supervisor or learner. Teams allow managers to assign courses, and allow the managers and supervisors to review the members progress of courses.</p> 
      <p>Currently, team creation and team membership management is done entirely manually, generally by managers “in the field”. Customers have expressed interest in having teams managed by admin users “at HQ”. This is particularly <strong>valuable at enterprise scale</strong> where organizations wish to enforce standard processes across their many different locations. Unfortunately, the current manual system is infeasible to manage at enterprise scale. In late 2019 I began leading the design work of this project.</p>

      <h2>Purpose</h2>
      <p>Teams Automation allows Inkling administrative users to <strong>create and manage Learning Pathway teams based on user attributes</strong>, to improve admin efficiency at enterprise scale.</p>

      <h2>The Concept</h2>
      <div className="ImageWrapper">
        <Img fluid={data.autoTeamResearchDiagram.childImageSharp.fluid} alt="Auto Team Research Diagram 1" />
      </div>
      <p>The main steps to create teams based on user attribute(s) are:</p>
      <p>1. Use Inkling's User Attribute Criteria (as used in other product areas) to <strong>define the user pool</strong> - users who will be split into teams (step 1 in the chart above). </p>
      <p>2.<strong> Group users(from step 1) into different teams</strong> by selecting 1 or more attributes (step 2 in the chart above). For example, selecting attribute "DepartmentID" might create teams "Product", "Sales", "Engineering".</p>
      <div className="ImageWrapper">
        <Img fluid={data.autoTeamResearchDiagram2.childImageSharp.fluid} alt="Auto Team Research Diagram 2" />
      </div>
      <p>If more than one attribute is selected, the teams are the <a href="https://en.wikipedia.org/wiki/Cartesian_product">Cartesian product</a> of those attribute values - but limited to only non-empty teams.
      Optionally, specifying a set of User Attribute Criteria to define who will be given the Manager and Supervisor roles are encouraged in teams creation process.</p>

      <h2>Concept Testing</h2>
      <div className="ImageWrapper">
        <Img fluid={data.autoTeamConcept.childImageSharp.fluid} alt="Auto Team Conpcet Mocks" />
      </div>
      <p>After a few white-boarding sessions with PM and Engineering lead I built an Invision prototype for concept testing with our administrative users. We shared the prototype link with users and asked questions to <strong>understand how would they use attribute(s) to group users and define team member roles</strong>, while walking them through the creation flow.</p>
      <h3>The need for teams automation and HQ control. </h3>
      <p>People preferred, and saw value in teams automation, they expected teams to be dynamic and can be synced automatically. People also love the idea to auto-generate team names by attribute values. Every customer we talked with said they would like the control of field edit permission, they wanted teams edit out of "fear" not "efficiency". </p>
      <h3>Data quality and the confusion of attribute grouping. </h3>
      <p>A lot of customers said their data wasn't clean enough for this solution or were just unsure how accurate their data is without taking a closer look. This includes inconsistency in attribute values, which is key given our solution utilizes grouping by attributes approach. </p>
      <p>Many customers have mapped their teams to a hierarchy/people reporting structure in other tools. How the group-by feature of automated teams worked wasn’t immediately obvious to customers and took some pretty lengthy explanations about how it works on our part.</p>
      <p>We did see some consistency among the customers we talked with how they’d want to organize their teams. Most teams would be location-based, grouping by region/district.</p>
      <h3>Why we are not exploring the full hierarchy/HRIS integration approach. </h3>
      <p>The full hierarchy and HRIS integration were alternative approaches that we talked about, as they are familiar to administrative users. We decided against going this route because, while familiar to the HQ admin, we’re not confident that this is actually what they need. We are not trying to create an HR system - we’re creating a tool to allows our users to better assign courses & review course reports. Another reason for staying away from a hierarchy approach is that people’s defined roles may not map perfectly to a role in Inkling. </p>
    
      <p style={{ marginTop: '1.5rem' }}>As we realized some users seemed unsure about their data and truly understand how to use attributes group teams, which is critical to ensure this feature's validity and success, we planed to invite our Design Technologist to help build a working prototype with real customer data for further validation. </p>

      <h2>Working Prototype & More Research Testings</h2>
      <div className="GifWrapper">
        <img src={prototypeGIF} alt="Auto Team Prototype" maxWidth="780px"/>
      </div>
      <p>I next worked closely with Design Technologist to build the working prototype. We decided to use as much existing components as possible for faster build. I iterated the mocks from the concept testing prototype and created mid-level fidelity mocks for her to use. I also added new ideas like Attribute Value Mapping which helps users to clean up their redundant data.   </p>
      <p>For this round of research testings we sent the working prototype link (with each customer's user data in Inkling's system) 10 minutes prior to research calls and asked one admin participant to create teams during the session. We were able to test the first time user experience as well as understand the users' thinking processes.    </p>
      <div className="ImageWrapper">
        <Img fluid={data.autoTeamAffinityMap.childImageSharp.fluid} alt="Auto Team Affinity Map" />
      </div>
      <h3>The issue of data quality is still there and will probably never go away.</h3>
      <p>What became apparent in these calls was that there isn’t just the problem of dirty, messy data - something we hope Attribute Value Mapping will help to alleviate. There is also the downside of HQ admins not being familiar with their user data. We noticed some HQ admins didn’t have a strong grasp or understanding of their user data, and consequently might not even be aware of what problems and inconsistencies exist.</p>

      <h3>Help users be more successful where we can.</h3>
      <p>What we can do, in light of this complexity, is to help users identify where things may be going wrong. Surfacing relevant feedback to help them troubleshoot is going to be really valuable. This includes:</p>
      <ul className="list">
        <li>Showing the number of teams, managers, supervisors that will be created.</li>
        <li>Allowing HQ Admins to preview every team.</li>
        <li>Identifying what teams will not have any managers.</li>
        <li>Showing a list of users who will not be sorted into a team.</li>
      </ul>
      <p>We heard in research, that some HQ Admins want visibility into every team created, particularly the Do-all HQ Admins. As such, we will add the possibility for an HQ admin to select a list of named users who will be added as manager on every Team.</p>

      <h3>Build for flexibility.</h3>
      <p>Inkling supports multiple industries and organizations, who will have different ways to organize their teams and different philosophies for managing them. Some will want to have everything managed at HQ, while others trust their field managers. Additionally, our customers need to have tools that allow them to keep up with internal business decisions and changes that will cause them to need to restructure their teams. By creating something that is flexible enough, Inkling can meet the needs of several types of customers.</p>
      <p>Ways we are doing this with the first phase of Teams Automation:</p>
      <ul className="list">
        <li>Making the team managers permissions configurable.</li>
        <li>Attribute Value Mapping to account for inconsistencies in their data.</li>
        <li>Allowing for manual edits after creations.</li>
        <li>Adding a new role - “Global Manager/Supervisor” to help Do-all HQ admins have visability into every team create.</li>
        </ul>

        <h2>Final Design</h2>
        <div className="ImageWrapper">
          <Img fluid={data.autoTeamDefault.childImageSharp.fluid} alt="Auto Team Final Design Default" />
        </div>
        <div className="ImageWrapper">
          <Img fluid={data.autoTeamGroupBy.childImageSharp.fluid} alt="Auto Team Final Design Group-by" />
        </div>
        <div className="ImageWrapper">
          <Img fluid={data.autoTeamRole.childImageSharp.fluid} alt="Auto Team Final Design Role" />
        </div>
        <div className="ImageWrapper">
          <Img fluid={data.autoTeamFilled.childImageSharp.fluid} alt="Auto Team Final Design Filled Rule" />
        </div>
        <div className="ImageWrapper">
          <Img fluid={data.autoTeamPreviewAll.childImageSharp.fluid} alt="Auto Team Final Design Preview All" />
        </div>
        <div className="ImageWrapper">
          <Img fluid={data.autoTeamPreviewATeam.childImageSharp.fluid} alt="Auto Team Final Design Preview A Team" />
        </div>
        <div className="ImageWrapper">
          <Img fluid={data.autoTeamSummary.childImageSharp.fluid} alt="Auto Team Final Design Summary" />
        </div>

        <h2>What's Next</h2>
        <p>We enabled Teams Automation v1 to 2 customers in May 2020. They were pleased with how easy it was to create teams and already set to go with the rules! The team was glad that they used Attribute Value Mapping to help identify and fix dirty data. We also heard the requests of proving lists of users from the rule creation warning section to help troubleshoot more quicly (which was scoped in v2 launch), as well as the ability to delete individual teams that aren't relevant to their use. The team re-prioritized v2 scope and continued collecting feedback internally and externally.   </p>

      



    </div>
    
   
  
    
    
    
    <div className="BottomNav">
      <div className="leftNav" >
      <Link to="/grading"><Arrow className="arrow"
            direction="left"
            shaftWidth={2}
            shaftLength={8}
            headWidth={10}
            headLength={8}
            />Previous project
      </Link>
      </div>
      <div className="rightNav">
        <Link to="/learningpathway">Next project<Arrow className="arrow"
          direction="right"
          shaftWidth={2}
          shaftLength={8}
          headWidth={10}
          headLength={8}
          />
          </Link>
        
      </div>
      
    </div>
    
  </Layout>
)

export default autoteams

export const query = graphql`
  query {
    autoTeamHero: file(relativePath: {eq: "auto-team-hero-image.png"}) {
      childImageSharp {
        fluid(maxWidth: 1112) {
         ...GatsbyImageSharpFluid
        }
      }
    }

    autoTeamResearchDiagram: file(relativePath: {eq: "auto-team-research-diagram.png"}) {
      childImageSharp {
        fluid(maxWidth: 780) {
         ...GatsbyImageSharpFluid
        }
      }
    }
    autoTeamResearchDiagram2: file(relativePath: {eq: "auto-team-research-diagram2.png"}) {
      childImageSharp {
        fluid(maxWidth: 780) {
         ...GatsbyImageSharpFluid
        }
      }
    }
    autoTeamConcept: file(relativePath: {eq: "auto-team-image-concept.png"}) {
      childImageSharp {
        fluid(maxWidth: 780) {
         ...GatsbyImageSharpFluid
        }
      }
    }
    autoTeamAffinityMap: file(relativePath: {eq: "auto-team-affinity-map.png"}) {
      childImageSharp {
        fluid(maxWidth: 780) {
         ...GatsbyImageSharpFluid
        }
      }
    }
    autoTeamDefault: file(relativePath: {eq: "auto-team-default.png"}) {
      childImageSharp {
        fluid(maxWidth: 780) {
         ...GatsbyImageSharpFluid
        }
      }
    }
    autoTeamGroupBy: file(relativePath: {eq: "auto-team-group-by.png"}) {
      childImageSharp {
        fluid(maxWidth: 780) {
         ...GatsbyImageSharpFluid
        }
      }
    }
    autoTeamRole: file(relativePath: {eq: "auto-team-role.png"}) {
      childImageSharp {
        fluid(maxWidth: 780) {
         ...GatsbyImageSharpFluid
        }
      }
    }
    autoTeamFilled: file(relativePath: {eq: "auto-team-filled-rule.png"}) {
      childImageSharp {
        fluid(maxWidth: 780) {
         ...GatsbyImageSharpFluid
        }
      }
    }
    autoTeamPreviewAll: file(relativePath: {eq: "auto-team-preview-all-teams.png"}) {
      childImageSharp {
        fluid(maxWidth: 780) {
         ...GatsbyImageSharpFluid
        }
      }
    }
    autoTeamPreviewATeam: file(relativePath: {eq: "auto-team-preview-a-team.png"}) {
      childImageSharp {
        fluid(maxWidth: 780) {
         ...GatsbyImageSharpFluid
        }
      }
    }
    autoTeamSummary: file(relativePath: {eq: "auto-team-summary.png"}) {
      childImageSharp {
        fluid(maxWidth: 780) {
         ...GatsbyImageSharpFluid
        }
      }
    }

  }
`
